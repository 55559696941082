// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  loaders: {
    list: false,
    reward: false,
  },
  reward: {},
};

const rewards = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_REWARD_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "TOGGLE_SEARCH_REWARD_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, list: action.visiblity },
      };
    case "GET_REWARD":
      return { ...state, reward: action.data };
    case "TOGGLE_GET_REWARD_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, reward: action.visiblity },
      };
    case "RESET_REWARD":
      return { ...state, reward: {} };
    default:
      return { ...state };
  }
};
export default rewards;
