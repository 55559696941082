import axios from "axios";
import { GenericAPIResponse, MySwal } from "../../../services/utils";
import { store } from "../../storeConfig/store";
import { getOrgNotesAsync, getUserNotesAsync } from "./notes.slice";
import { AddOrgNoteResponse } from "./types/add-org-note.type";
import { AddUserNoteResponse } from "./types/add-user-note.type";
import {
  GetOrgNotesPayload,
  GetOrgNotesResponse,
} from "./types/get-org-notes.type";
import {
  AddNotePayload,
  AddOrgAndUserNotePayload,
  GetUserNotesPayload,
  GetUserNotesResponse,
  PostNotePayload,
} from "./types/get-user-notes.types";
import { getTeam } from "../../../views/superfone/team/store/action";

export const getUserNotes = async (payload: GetUserNotesPayload) => {
  const response = await axios.get<GenericAPIResponse<GetUserNotesResponse>>(
    `/users/notes/${payload.id}`
  );
  return response?.data?.data || [];
};

export const getOrgNotes = async (payload: GetOrgNotesPayload) => {
  const response = await axios.get<GenericAPIResponse<GetOrgNotesResponse>>(
    `/org/notes/${payload.id}`
  );
  return response?.data?.data || [];
};

export const addUserNote = async (payload: PostNotePayload) => {
  const { id, note } = payload;
  const response = await axios.post<GenericAPIResponse<AddUserNoteResponse>>(
    `/users/notes`,
    {
      note,
      user_id: id,
    }
  );
  return response;
};

export const addOrgNote = async (payload: PostNotePayload) => {
  const { id, note } = payload;
  const response = await axios.post<GenericAPIResponse<AddOrgNoteResponse>>(
    `/org/notes`,
    {
      note,
      org_id: id,
    }
  );
  return response;
};

export const generateNote = async (payload: AddNotePayload) => {
  const dispatch = store.dispatch;
  const user = localStorage.getItem("userData");
  const userData = user ? JSON.parse(user).userData : null;
  const title = `Agent - ${userData.first_name} ${userData.last_name}`;
  const { type, action_name, id, closeable = false } = payload;
  const action = type === "user" ? addUserNote : addOrgNote;
  return await MySwal.fire({
    title,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    inputLabel: "Please enter note",
    showCancelButton: false,
    confirmButtonText: "OK",
    showLoaderOnConfirm: true,
    showCloseButton: closeable,
    preConfirm: (comment) => {
      const note = `Action: ${action_name}, comment: ${comment}`;
      return action({
        note,
        id,
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          dispatch(
            type === "user"
              ? getUserNotesAsync({ id })
              : getOrgNotesAsync({ id })
          );
          return response;
        })
        .catch((error) => {
          MySwal.showValidationMessage(`Request failed: ${error}`);
        });
    },
    allowOutsideClick: false,
  });
};

export const setOrgEmail = async (payload: {
  org_id: number;
  updateApi: (email: string) => Promise<boolean>;
}) => {
  const dispatch = store.dispatch;
  const { org_id, updateApi } = payload;
  return await MySwal.fire({
    title: "Need to set email before you can create subscription",
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    inputLabel: "Please enter email",
    showCancelButton: false,
    confirmButtonText: "OK",
    showLoaderOnConfirm: true,
    showCloseButton: false,
    preConfirm: (email) => {
      return updateApi(email)
        .then((response) => {
          if (!response) {
            alert("Failed to set email");
          }
          dispatch(getTeam(org_id));
          return response;
        })
        .catch((error) => {
          MySwal.showValidationMessage(`Request failed: ${error}`);
        });
    },
    allowOutsideClick: false,
  });
};

export const generateOrgAndUserNote = async (
  payload: AddOrgAndUserNotePayload
) => {
  const dispatch = store.dispatch;
  const user = localStorage.getItem("userData");
  const userData = user ? JSON.parse(user).userData : null;
  const title = `Agent - ${userData.first_name} ${userData.last_name}`;
  const { org_id, user_id, action_name, closeable = false } = payload;
  return await MySwal.fire({
    title,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    inputLabel: "Please enter note",
    showCancelButton: false,
    confirmButtonText: "OK",
    showLoaderOnConfirm: true,
    showCloseButton: closeable,
    preConfirm: (comment) => {
      const note = `Action: ${action_name}, comment: ${comment}`;
      return Promise.all([
        addUserNote({ id: user_id, note }),
        addOrgNote({ id: org_id, note }),
      ])
        .then((response) => {
          dispatch(getUserNotesAsync({ id: user_id }));
          dispatch(getOrgNotesAsync({ id: org_id }));
          return response;
        })
        .catch((error) => {
          MySwal.showValidationMessage(`Request failed: ${error}`);
        });
    },
    allowOutsideClick: false,
  });
};
