import axios from "axios";
import { InvitedStaff, Org, OrgSearchResponse, Presence } from "./team.types";
import { RecentDevice } from "./types/recent-device";
import debounce from "lodash/debounce";
import { store } from "../../../../redux/storeConfig/store";
import { generateOrgAndUserNote } from "../../../../redux/slices/notes/notes.api";

export const searchTeamsAndSuperfone = async (
  page: number,
  perPage: number,
  status: string,
  q: string,
  action?: Function
): Promise<OrgSearchResponse> => {
  const response: any = await axios.get("/org/search", {
    params: {
      page,
      page_size: perPage,
      status,
      text: q,
    },
  });
  if (response.status === 200) {
    if (action) {
      store.dispatch(action(response.data.data));
    }
    return response.data.data;
  } else {
    throw new Error(response?.data?.data?.message || "");
  }
};

export const debouncedTeamsSearch = debounce(searchTeamsAndSuperfone, 1000);

export const inviteMember = async (payload: {
  first_name: string;
  last_name: string;
  phone: string;
  org_id: number;
}): Promise<InvitedStaff> => {
  const { first_name, last_name, org_id, phone } = payload;
  const response: any = await axios.post("/admin/users/invite", {
    first_name,
    last_name,
    phone,
    org_id,
  });
  console.log(response);
  await generateOrgAndUserNote({
    action_name: `User id ${
      response?.data?.data?.id || 0
    } added to org ${org_id}`,
    org_id: org_id,
    user_id: response?.data?.data?.id || 0,
  });
  if (response.status === 200) {
    return response.data.data;
  } else {
    throw new Error(response?.data?.data?.message || "");
  }
};

export const searchOrgsThroughSuperfones = async (
  superfones: string[]
): Promise<Org[]> => {
  const promises = superfones.map((superfone) => {
    return searchTeamsAndSuperfone(1, 100, "", superfone);
  });
  const responses = await Promise.allSettled(promises);
  const orgs: Org[] = [];
  responses
    .map((response) => {
      const { status } = response;
      if (status === "fulfilled") {
        if (response?.value?.rows?.[0]) {
          orgs.push(response.value.rows[0]);
        }
      }
      return null;
    })
    .filter((e) => e);
  return orgs;
};

export const getPresenceOfUsers = async (userIds: number[]) => {
  const promises = userIds.map((userId) => {
    return axios.get(`/users/${userId}/softphone`);
  });
  try {
    const responses = await Promise.allSettled(promises);
    const presences: Presence[] = [];
    responses
      .map((response) => {
        const { status } = response;
        if (status === "fulfilled") {
          presences.push(response.value.data.data);
        }
        return null;
      })
      .filter((e) => e);
    return presences;
  } catch (e) {
    console.error(e);
  }
};

export const getDeviceDetailsOfUsers = async (userIds: number[]) => {
  const promises = userIds.map((userId) => {
    return axios.get(`/users/${userId}/recent-device`);
  });
  try {
    const responses = await Promise.allSettled(promises);
    const deviceDetails: RecentDevice[] = [];
    responses
      .map((response) => {
        const { status } = response;
        if (status === "fulfilled") {
          deviceDetails.push(response.value.data.data);
        }
        return null;
      })
      .filter((e) => e);
    return deviceDetails;
  } catch (e) {
    console.error(e);
  }
};
