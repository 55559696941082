import { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";
import { StyledWrapper } from "./wrapper.styles";

export interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  type?: "horizontal" | "vertical";
  gap?: string;
  padding?: string | number;
  flex?: string;
  background?: string;
  border?: string;
  fullWidth?: boolean;
  center?: boolean;
  transition?: CSSProperties["transition"];
  align?:
    | "normal"
    | "stretch"
    | "center"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "first baseline"
    | "last baseline"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset";
  justify?:
    | "center"
    | "normal"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset"
    | "flex-end"
    | "flex-start"
    | "end"
    | "start"
    | "left"
    | "right";
  flexWrap?:
    | "inherit"
    | "initial"
    | "nowrap"
    | "revert"
    | "unset"
    | "wrap"
    | "wrap-reverse";
  flexType?: "inline";
  borderRadius?: string;
}

export const Wrapper = (
  props: Readonly<PropsWithChildren<WrapperProps>>
): JSX.Element => {
  const {
    children,
    type = "horizontal",
    gap = "",
    padding = "",
    flex = "",
    className,
    style,
    align = "",
    justify = "",
    flexWrap = "",
    flexType = "",
    background = "",
    fullWidth = false,
    center = false,
    border = "",
    transition = "",
    borderRadius = "",
    onClick,
    ...rest
  } = props;
  return (
    <StyledWrapper
      $type={type === "vertical" ? "column" : "row"}
      $align={center ? "center" : align}
      $gap={gap}
      $flexType={flexType}
      $padding={typeof padding === "number" ? `${padding}px` : padding}
      $flexWrap={flexWrap}
      $flex={flex}
      $border={border}
      $borderRadius={borderRadius}
      $background={background}
      $transition={transition}
      $fullWidth={fullWidth}
      $justify={center ? "center" : justify}
      className={className}
      style={style}
      $cursor={!!onClick}
      onClick={onClick}
      {...rest}
    >
      {children}
    </StyledWrapper>
  );
};
