// ** Initial State
const initialState = {
  trialCallHistoryData: [],
  trialCallHistoryTotal: 1,
  params: {},
  loaders: {
    trialCallHistory: false,
    trialCallDataUpdate: false,
  },
};

const callHistory = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_TRIAL_CALL_HISTORY_DATA":
      return {
        ...state,
        trialCallHistoryData: action.data,
        trialCallHistoryTotal: action.total,
        params: action.params,
      };
    case "TOGGLE_TRIAL_CALL_HISTORY_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, trialCallHistory: action.visiblity },
      };
    case "TOGGLE_TRIAL_CALL_DATA_UPDATE_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, trialCallDataUpdate: action.visiblity },
      };
    default:
      return { ...state };
  }
};
export default callHistory;
