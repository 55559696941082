// ** Initial State
const initialState = {
  inviteCodes: [],
  inviteCodes_total: 0,
};

const InviteCodes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVITE_CODES":
      return {
        ...state,
        inviteCodes: action.data,
        inviteCodes_total: action.total,
      };
    default:
      return { ...state };
  }
};
export default InviteCodes;
