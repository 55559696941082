// ** Initial State
const initialState = {
    shortUrls: [],
    shortUrls_total: 0,
    selectedTeam: null
};

const ShortUrls = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_SHORT_URLS":
            return {
                ...state,
                shortUrls: action.data,
                shortUrls_total: action.total,
            };
        default:
            return {...state};
    }
};
export default ShortUrls;
