import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/storeConfig/store";
import { CallerTuneResponse } from "./action/caller-tune.types";
import { TeamVoip } from "./types/team-voip";
import { Org } from "./types/search-results";

export const selectTeamsReducer = (state: RootState) => state.teams;

export const selectTeamsKYC = createSelector(
  [selectTeamsReducer],
  (reducer) => reducer.kyc
);

export const selectUsers = createSelector(
  [selectTeamsReducer],
  (reducer) => reducer.users
);

export const selectTeamLoaders = createSelector(
  [selectTeamsReducer],
  (reducer) => reducer.loaders
);

export const selectSelectedTeam = createSelector(
  [selectTeamsReducer],
  (reducer) => reducer.selectedTeam
);

export const selectSearchTeams = createSelector(
  [selectTeamsReducer],
  (reducer) => reducer.allData as Org[]
);

export const selectCallerTune = createSelector(
  [selectTeamsReducer],
  (reducer) => reducer?.teamVoiceMessage as CallerTuneResponse
);

export const selectVoip = createSelector(
  [selectTeamsReducer],
  (reducer) => reducer.teamVoip as TeamVoip
);
