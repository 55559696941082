import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

export const getTrialHistoryApiFilter = (durationRange) => {
  let apiFilter = {};
  switch (durationRange) {
    case "EQUAL_0":
      apiFilter = { ...apiFilter, duration_eq: 0 };
      break;
    case "GT_EQUAL_1_LT_30":
      apiFilter = { ...apiFilter, duration_gt: 1, duration_lt: 30 };
      break;
    case "GT_EQUAL_30_LT_60":
      apiFilter = { ...apiFilter, duration_gt: 30, duration_lt: 60 };
      break;
    case "GT_EQUAL_60":
      apiFilter = { ...apiFilter, duration_gt: 60 };
      break;
    default:
      break;
  }
  return apiFilter;
};

// ** Get data on page or row change
export const searchAndFilter = (params) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_TRIAL_CALL_HISTORY_LOADER", visiblity: true });
    const { page, perPage, status, q, callType, durationRange } = params || {};

    const apiFilter = getTrialHistoryApiFilter(durationRange);
    await axios
      .get("/call-history/trial", {
        params: {
          page: page - 1,
          page_size: perPage,
          text: q,
          status,
          call_type: callType,
          include_soft_deleted: true,
          ...apiFilter,
        },
      })
      .then((response) => {
        dispatch({
          type: "SEARCH_TRIAL_CALL_HISTORY_DATA",
          data: response.data.data.rows,
          total: response.data.data.total_items,
          params,
        });
      })
      .catch((err) => console.log(err))
      .then(() => {
        dispatch({
          type: "TOGGLE_TRIAL_CALL_HISTORY_LOADER",
          visiblity: false,
        });
      });
  };
};

export const updateCdr = (callId, updateData) => {
  return async (dispatch, getState) => {
    dispatch({ type: "TOGGLE_TRIAL_CALL_DATA_UPDATE_LOADER", visiblity: true });
    const store = getState();
    await axios
      .put(`/call-history/${callId}`, updateData)
      .then((response) => {
        dispatch(searchAndFilter(store?.callHistory?.params || {}));
        MySwal.fire({
          icon: "success",
          title: "CDR updated successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() => {
        dispatch({
          type: "TOGGLE_TRIAL_CALL_DATA_UPDATE_LOADER",
          visiblity: false,
        });
      });
  };
};
