// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  loaders: {
    action: false,
  },
};

const WhatsappAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL":
      return {
        ...state,
        data:  action.data
      }
    case "CREATE_NEW":
      return {
        ...state,
      };
    case "UPDATE":
      return {
        ...state,
      };
    default:
      return {...state};
  }
};
export default WhatsappAccountsReducer;
