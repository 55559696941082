import { getCallRecording } from "../redux/slices/call-recording.slice";
import { saveAs } from "file-saver";
import { Slide, toast } from "react-toastify";
import { SerializedError } from "@reduxjs/toolkit";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios, { AxiosError, AxiosResponse } from "axios";

export const MySwal = withReactContent(Swal);

export type IdleAPIResponse = Readonly<{
  response: null;
  error: null;
  status: "idle";
}>;

export type LoadingAPIResponse = Readonly<{
  response: null;
  error: null;
  status: "loading";
}>;

export type PreservedLoadingAPIResponse<Type> = Readonly<{
  response: Type;
  error: null;
  status: "loading";
}>;

export type SuccessAPIResponse<Type> = Readonly<{
  response: Type;
  status: "success";
  error: null;
}>;

export type FailedAPIResponse = Readonly<{
  response: null;
  error: SerializedError;
  status: "failed";
}>;

export type APIResponse<Type> =
  | IdleAPIResponse
  | LoadingAPIResponse
  | SuccessAPIResponse<Type>
  | FailedAPIResponse
  | PreservedLoadingAPIResponse<Type>;

export const initialApiResponse: IdleAPIResponse = {
  response: null,
  error: null,
  status: "idle",
};

export const loadingApiResponse: LoadingAPIResponse = {
  response: null,
  error: null,
  status: "loading",
};

export const downloadCallRecordingFromId = async (id: number) => {
  const response = await getCallRecording(id);
  if (response?.recording_url) {
    saveAs(response.recording_url);
  }
};

export const openCallRecording = async (id: number) => {
  if (!id) {
    toast.error(`Invalid Call Recording ID: ${id}`, {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 2000,
    });
    return;
  }
  const response = await getCallRecording(id);
  if (!response?.recording_url) {
    toast.error(`No url found`, {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 2000,
    });
    return;
  }
  window.open(response.recording_url, "_blank");
};

export const isEqual = (
  a?: number | string | null,
  b?: number | string | null
) => {
  if (a !== null && a !== undefined && b !== null && b !== undefined) {
    return String(a) === String(b);
  }
  return false;
};

export type SelectOption = {
  label: string;
  value: string;
};

export type GenericAPIResponse<T> = {
  data: T;
  message: string;
};

export type GenericAPIError = {
  stack: string;
  message: string;
};

export const GenericErrorMessage = "Something went wrong...";

export const getDataOrError = (
  response: AxiosResponse<GenericAPIResponse<unknown>>
) => {
  if (response.status === 200) {
    return response.data.data;
  } else {
    if (axios.isAxiosError(response)) {
      throw new Error(
        (response as AxiosError<GenericAPIError>).response?.data?.message || ""
      );
    } else {
      throw new Error(GenericErrorMessage + " " + JSON.stringify(response));
    }
  }
};

export const convertToLabelValue = (obj: any) => {
  if (obj) {
    return {
      label:
        obj.name ||
        obj.title ||
        `${obj.first_name || ""} ${obj.last_name || ""}`.trim(),
      value: obj.id,
    } as SelectOption;
  }
  return undefined;
};

export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  let array = [...arr];
  if (new_index >= array.length) {
    var k = new_index - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(new_index, 0, array.splice(old_index, 1)[0]);
  return array;
};
