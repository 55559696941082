import { createSelector, createSlice } from "@reduxjs/toolkit";
import { APIResponse, initialApiResponse } from "../../../services/utils";
import { OrgSearchResponse } from "../team/store/team.types";
import { RootState } from "../../../redux/storeConfig/store";

type IntegrationState = Readonly<{
  searchOrgResponse: APIResponse<OrgSearchResponse>;
  searchUpdateOrgResponse: APIResponse<OrgSearchResponse>;
}>;

const initialState: IntegrationState = {
  searchOrgResponse: initialApiResponse,
  searchUpdateOrgResponse: initialApiResponse,
};

export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    setSearchOrgResponse: (state, action) => {
      state.searchOrgResponse = {
        response: action.payload,
        status: "success",
        error: null,
      };
    },
    setSearchUpdateOrgResponse: (state, action) => {
      state.searchUpdateOrgResponse = {
        response: action.payload,
        status: "success",
        error: null,
      };
    },
  },
});

export const { setSearchOrgResponse, setSearchUpdateOrgResponse } =
  integrationSlice.actions;

export const selectIntegrationsSlice = (state: RootState) => state.integrations;

export const selectSearchedResponse = createSelector(
  [selectIntegrationsSlice],
  (reducer) => {
    return reducer.searchOrgResponse;
  }
);

export const selectSearchedUpdateResponse = createSelector(
  [selectIntegrationsSlice],
  (reducer) => {
    return reducer.searchUpdateOrgResponse;
  }
);

export default integrationSlice.reducer;
