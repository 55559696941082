// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedCustomer: null,
  customerNotes: {
    data: [],
    total: 1,
    params: {},
  },
  customerReminders: {
    data: [],
    total: 1,
    params: {},
  },
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CUSTOMER_DATA":
      return { ...state, allData: action.data };
    case "SEARCH_CUSTOMER_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "GET_CUSTOMER":
      return { ...state, selectedCustomer: action.selectedCustomer };
    case "GET_CUSTOMER_NOTES":
      return {
        ...state,
        customerNotes: {
          ...state.customerNotes,
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    case "GET_CUSTOMER_REMINDERS":
      return {
        ...state,
        customerReminders: {
          ...state.customerReminders,
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    default:
      return { ...state };
  }
};
export default customers;
