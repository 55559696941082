import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/storeConfig/store";

type KYCTableState = Readonly<{
  manageColumnsModal: boolean;
  selectedColumns: any[];
}>;

const initialState: KYCTableState = {
  manageColumnsModal: false,
  selectedColumns: [],
};

export const kycTableSlice = createSlice({
  name: "kyc-table",
  initialState,
  reducers: {
    setManageColumnsModal: (state, action: PayloadAction<boolean>) => {
      state.manageColumnsModal = action.payload;
    },
    setColumns: (state, action: PayloadAction<any[]>) => {
      state.selectedColumns = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setManageColumnsModal, setColumns } = kycTableSlice.actions;

export const selectKycTableReducer = (state: RootState) => state.kycTable;

export const selectManageColumnsModal = createSelector(
  [selectKycTableReducer],
  (kyc) => {
    return kyc.manageColumnsModal;
  }
);

export const selectColumns = createSelector([selectKycTableReducer], (kyc) => {
  return kyc.selectedColumns;
});

export default kycTableSlice.reducer;
