import styled from "styled-components";

type StyledWrapperProps = Readonly<{
  $type: string;
  $gap: string;
  $padding: string;
  $flex: string;
  $align: string;
  $justify: string;
  $cursor: boolean;
  $flexWrap: string;
  $flexType: string;
  $background: string;
  $fullWidth: boolean;
  $border: string;
  $transition: string;
  $borderRadius: string;
}>;

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: ${({ $flexType }) => {
    return $flexType === "inline" ? "inline-flex" : "flex";
  }};

  ${({ $type }) =>
    $type
      ? `
        flex-direction:${$type};
        ${$type.includes("column") ? "align-items:stretch;" : ""}
        `
      : ""}
  ${({ $padding }) =>
    $padding
      ? `
        padding:${$padding};
        `
      : ""}
  ${({ $gap }) =>
    $gap
      ? `
        gap:${$gap};
        `
      : ""}
  ${({ $flex }) =>
    $flex
      ? `
        flex:${$flex};
        `
      : ""}
  ${({ $align }) =>
    $align
      ? `
        align-items:${$align};
        `
      : ""}
  ${({ $justify }) =>
    $justify
      ? `
      justify-content:${$justify};
        `
      : ""}
  ${({ $cursor }) =>
    $cursor
      ? `
      cursor:pointer;
        `
      : ""}
  ${({ $flexWrap }) =>
    $flexWrap
      ? `
      flex-wrap:${$flexWrap};
        `
      : ""}

${({ $background }) =>
    $background
      ? `
      background:${$background};
        `
      : ""}
${({ $fullWidth }) =>
    $fullWidth
      ? `
      width:100%;
        `
      : ""}

  ${({ $border }) => {
    return $border ? `border:${$border};` : "";
  }}

  ${({ $borderRadius }) => {
    return $borderRadius ? `border-radius:${$borderRadius};` : "";
  }}

  ${({ $transition }) => {
    return $transition ? `transition:${$transition};` : "";
  }}
`;
