import axios from "axios";
import { FetchUserPayload, FetchUserResponse } from "./types/fetch-user.types";
import { GenericAPIResponse } from "../../../../services/utils";
import {
  GetUserFormDataPayload,
  GetUserFormResponse,
} from "./types/get-user-form-data.types";
import {
  PostUserFormDataPayload,
  PostUserFormDataResponse,
} from "./types/post-user-form-data.types";
import { AddUserPayload, AddUserResponse } from "./types/add-user.types";
import { generateNote } from "../../../../redux/slices/notes/notes.api";

export const onSearchUser = async (params: FetchUserPayload) => {
  const { order = "DESC", page, page_size, status, text } = params;
  const response = await axios.get<GenericAPIResponse<FetchUserResponse>>(
    "/users/search",
    {
      params: {
        ...(page ? { page } : {}),
        ...(order ? { order } : {}),
        ...(page_size ? { page_size } : {}),
        ...(status ? { status } : {}),
        ...(text ? { text } : {}),
      },
    }
  );
  return response.data.data;
};

export const getUserFormData = async (payload: GetUserFormDataPayload) => {
  const { user_id } = payload;
  const response = await axios.get<GenericAPIResponse<GetUserFormResponse>>(
    `/admin/org/onboarding-ui-config/${user_id}`
  );
  return response.data.data;
};

export const postUserFormData = async (payload: PostUserFormDataPayload) => {
  const response = await axios.post<
    GenericAPIResponse<PostUserFormDataResponse>
  >(`/admin/org/onboard`, payload);
  return response.data.data;
};

export const addUser = async (payload: AddUserPayload) => {
  const response = await axios.post<GenericAPIResponse<AddUserResponse>>(
    "/admin/users",
    payload
  );
  const user = response.data.data;
  if (user?.id) {
    await generateNote({
      id: user.id,
      action_name: "Created User from CxD Onboarding",
      type: "user",
    });
  }
  return user;
};
