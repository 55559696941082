// ** Redux, Thunk & Root Reducer Imports
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";
import rootReducer from "../reducers/rootReducer";
import {
  persistStore,
  persistReducer,
  PersistConfig,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { superfoneApi } from "../api/superfone-api";
export type RootReducer = ReturnType<typeof rootReducer>;

type ExtendedPersistConfig = PersistConfig<RootReducer> & {
  whitelist: (keyof RootReducer)[];
};

const persistConfig: ExtendedPersistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["kycTable"],
};

// ** init middleware
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk, createDebounce(), superfoneApi.middleware];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware);
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);

export type LoaderState = "idle" | "loading" | "success" | "failed";
