import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIResponse, initialApiResponse } from "../../../services/utils";
import { getOrgNotes, getUserNotes } from "./notes.api";
import {
  GetOrgNotesPayload,
  GetOrgNotesResponse,
} from "./types/get-org-notes.type";
import {
  GetUserNotesPayload,
  GetUserNotesResponse,
} from "./types/get-user-notes.types";

type NotesState = Readonly<{
  getUserNotes: APIResponse<GetUserNotesResponse>;
  getOrgNotes: APIResponse<GetOrgNotesResponse>;
}>;

const initialState: NotesState = {
  getUserNotes: initialApiResponse,
  getOrgNotes: initialApiResponse,
};

export const getUserNotesAsync = createAsyncThunk(
  "notes/get-user-notes",
  async (payload: GetUserNotesPayload) => {
    const response = await getUserNotes(payload);
    return response;
  }
);

export const getOrgNotesAsync = createAsyncThunk(
  "notes/get-org-notes",
  async (payload: GetOrgNotesPayload) => {
    const response = await getOrgNotes(payload);
    return response;
  }
);

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserNotesAsync.pending, (state) => {
        state.getUserNotes = {
          response: null,
          status: "loading",
          error: null,
        };
      })
      .addCase(getUserNotesAsync.fulfilled, (state, action) => {
        state.getUserNotes = {
          response: action.payload,
          status: "success",
          error: null,
        };
      })
      .addCase(getUserNotesAsync.rejected, (state, action) => {
        state.getUserNotes = {
          response: null,
          status: "failed",
          error: action.error,
        };
      })
      .addCase(getOrgNotesAsync.pending, (state) => {
        state.getOrgNotes = {
          response: null,
          status: "loading",
          error: null,
        };
      })
      .addCase(getOrgNotesAsync.fulfilled, (state, action) => {
        state.getOrgNotes = {
          response: action.payload,
          status: "success",
          error: null,
        };
      })
      .addCase(getOrgNotesAsync.rejected, (state, action) => {
        state.getOrgNotes = {
          response: null,
          status: "failed",
          error: action.error,
        };
      });
  },
});

export default notesSlice.reducer;
