import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoaderState } from "../../../../redux/storeConfig/store";
import { APIResponse, initialApiResponse } from "../../../../services/utils";
import { InvitedStaff, Org, Presence } from "./team.types";
import {
  getDeviceDetailsOfUsers,
  getPresenceOfUsers,
  inviteMember,
  searchOrgsThroughSuperfones,
} from "./teams.api";
import { RecentDevice } from "./types/recent-device";

type TeamState = Readonly<{
  presences: Presence[];
  presencesStatus: LoaderState;

  searchedOrgs: Org[];
  searchedOrgsStatus: LoaderState;

  inviteMemberResponse: APIResponse<InvitedStaff>;
  getDevicesDetails: APIResponse<RecentDevice[]>;
}>;

const initialState: TeamState = {
  presences: [],
  presencesStatus: "idle",

  searchedOrgs: [],
  searchedOrgsStatus: "idle",

  inviteMemberResponse: initialApiResponse,
  getDevicesDetails: initialApiResponse,
};

export const teamSlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPresenceOfUsersAsync.pending, (state) => {
        state.presencesStatus = "loading";
      })
      .addCase(getPresenceOfUsersAsync.fulfilled, (state, action) => {
        state.presences = action.payload;
        state.presencesStatus = "success";
      })
      .addCase(getPresenceOfUsersAsync.rejected, (state) => {
        state.presencesStatus = "failed";
      })
      .addCase(searchOrgsThroughSuperfonesAsync.pending, (state) => {
        state.searchedOrgsStatus = "loading";
      })
      .addCase(searchOrgsThroughSuperfonesAsync.fulfilled, (state, action) => {
        state.searchedOrgs = action.payload;
        state.searchedOrgsStatus = "success";
      })
      .addCase(searchOrgsThroughSuperfonesAsync.rejected, (state) => {
        state.searchedOrgsStatus = "failed";
      })
      .addCase(inviteMemberAsync.pending, (state) => {
        state.inviteMemberResponse = {
          response: null,
          status: "loading",
          error: null,
        };
      })
      .addCase(inviteMemberAsync.fulfilled, (state, action) => {
        state.inviteMemberResponse = {
          response: action.payload,
          status: "success",
          error: null,
        };
      })
      .addCase(inviteMemberAsync.rejected, (state, action) => {
        state.inviteMemberResponse = {
          response: null,
          status: "failed",
          error: new Error(action?.error?.message),
        };
      })
      .addCase(getDeviceDetailsOfUsersAsync.pending, (state) => {
        state.getDevicesDetails = {
          response: null,
          status: "loading",
          error: null,
        };
      })
      .addCase(getDeviceDetailsOfUsersAsync.fulfilled, (state, action) => {
        state.getDevicesDetails = {
          response: action.payload,
          status: "success",
          error: null,
        };
      })
      .addCase(getDeviceDetailsOfUsersAsync.rejected, (state, action) => {
        state.getDevicesDetails = {
          response: null,
          status: "failed",
          error: new Error(action?.error?.message),
        };
      });
  },
});

export default teamSlice.reducer;

export const getPresenceOfUsersAsync = createAsyncThunk(
  "team/get-presences-of-users",
  async (userIds: number[]): Promise<Presence[]> => {
    const presences = await getPresenceOfUsers(userIds);
    return presences || [];
  }
);

export const getDeviceDetailsOfUsersAsync = createAsyncThunk(
  "team/get-device-details-of-users",
  async (userIds: number[]): Promise<RecentDevice[]> => {
    const deviceDetails = await getDeviceDetailsOfUsers(userIds);
    return deviceDetails || [];
  }
);

export const searchOrgsThroughSuperfonesAsync = createAsyncThunk(
  "team/get-orgs-of-superfones",
  async (superfones: string[]) => {
    const orgs = await searchOrgsThroughSuperfones(superfones);
    return orgs || [];
  }
);

export const inviteMemberAsync = createAsyncThunk(
  "team/invite-member",
  async (payload: {
    first_name: string;
    last_name: string;
    phone: string;
    org_id: number;
  }) => {
    const invitedStaff = await inviteMember(payload);
    return invitedStaff;
    // return {
    //   user_name: "su_1661",
    //   org_creation_limit: 3,
    //   id: 1661,
    //   phone: "+911212121212",
    //   first_name: "sds",
    //   last_name: "sds",
    //   state: "invited",
    //   access_level: "MEMBER",
    //   updatedAt: "2023-03-22T13:20:05.831Z",
    //   createdAt: "2023-03-22T13:20:05.831Z",
    //   email: null,
    //   password: null,
    //   deletedAt: null,
    // };
  }
);
