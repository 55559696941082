// ** Initial State
const initialState = {
  allData: [],
  data: [],
  count: 1,
  params: {},
  customers: {},
  customers_count: 1,
  notes: {},
  notes_count: 1,
  reminders: {},
  reminders_count: 1,
  selectedTeam: null,
  subscriptions: [],
  dailyTalktimes: [],
  users: [],
  settings: {},
  kyc: {},
  paymentLinks: [],
  datePickerData: {},
  callHistoryStats: {},
  talkTime: {},
  talkTimeLedger: [],
  loaders: {
    team: false,
    users: false,
    subscriptions: false,
    dailyTalktimes: false,
    settings: false,
    kyc: false,
    callHistoryStats: false,
    paymentLinks: false,
    teamCallHistory: false,
    plans: false,
    teamPresence: false,
    teamVoip: false,
    teamVoiceMessage: false,
    teamVoipHistory: false,
    teamInternalLeads: false,
    eventActions: false,
  },
  plans: [],
  teamPresence: [],
  teamVoip: {},
  teamVoiceMessage: {},
  teamVoipHistory: {
    data: [],
    count: 1,
    params: {},
  },
  updateSubscriptionId: 0,
  teamInternalLeads: {
    data: [],
    count: 1,
    params: {},
  },
  eventActions: [],
  updateEventActionId: 0,
  viewEventActionId: 0,
};

const teams = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_TEAMS_DATA":
      return {
        ...state,
        allData: action.data,
        count: action.count,
      };
    case "SEARCH_TEAMS_DATA":
      return {
        ...state,
        allData: action.data,
        count: action.count,
        params: action.params,
      };
    case "GET_TEAM":
      return { ...state, selectedTeam: action.selectedTeam };
    case "ADD_TEAM":
      return { ...state };
    case "DELETE_TEAM":
      return { ...state };
    case "GET_TEAM_CUSTOMERS":
      return {
        ...state,
        customers: action.data,
        customers_count: action.count,
      };
    case "GET_TEAM_NOTES":
      return {
        ...state,
        notes: action.data,
        notes_count: action.count,
      };
    case "GET_TEAM_REMINDERS":
      return {
        ...state,
        reminders: action.data,
        reminders_count: action.count,
      };
    case "GET_TEAM_SUBSCRIPTIONS":
      return {
        ...state,
        subscriptions: action.data,
      };
    case "GET_TEAM_DAILY_TALKTIMES":
      return {
        ...state,
        dailyTalktimes: action.data,
      };
    case "GET_TEAM_USERS":
      return {
        ...state,
        users: action.data,
      };
    case "GET_TEAM_SETTINGS":
      return {
        ...state,
        settings: action.data,
      };
    case "GET_TEAM_KYC":
      return {
        ...state,
        kyc: action.data,
      };
    case "RESET_TEAM_USERS":
      return {
        ...state,
        users: [],
      };
    case "GET_TEAM_PAYMENT_LINKS":
      return {
        ...state,
        paymentLinks: action.data,
      };
    case "RESET_TEAM_SUBSCRIPTIONS":
      return {
        ...state,
        subscriptions: [],
      };
    case "RESET_TEAM_DAILY_TALKTIMES":
      return {
        ...state,
        dailyTalktimes: [],
      };
    case "RESET_TEAM_SETTINGS":
      return {
        ...state,
        settings: {},
      };
    case "SET_DATE_PICKER_DATA":
      return {
        ...state,
        datePickerData: { ...state.datePickerData, ...action.data },
      };
    case "RESET_DATE_PICKER_DATA":
      return {
        ...state,
        datePickerData: {},
      };
    case "GET_TEAM_CALL_HISTORY_STATS":
      return {
        ...state,
        callHistoryStats: action.data,
      };
    case "RESET_TEAM_CALL_HISTORY_STATS":
      return {
        ...state,
        callHistoryStats: {},
      };
    case "TOGGLE_GET_TEAM_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, team: action.visiblity },
      };
    case "TOGGLE_GET_TEAM_USERS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, users: action.visiblity },
      };
    case "TOGGLE_GET_TEAM_SUBSCRIPTIONS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, subscriptions: action.visiblity },
      };
    case "TOGGLE_GET_TEAM_DAILY_TALKTIMES_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, dailyTalktimes: action.visiblity },
      };
    case "TOGGLE_GET_TEAM_SETTINGS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, settings: action.visiblity },
      };
    case "TOGGLE_GET_TEAM_KYC_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, kyc: action.visiblity },
      };
    case "TOGGLE_GET_TEAM_CALL_HISTORY_STATS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, callHistoryStats: action.visiblity },
      };
    case "TOGGLE_GET_TEAM_PAYMENT_LINKS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, paymentLinks: action.visiblity },
      };
    case "RESET_TEAM_INFO":
      return { ...state, selectedTeam: null };
    case "TOGGLE_GET_PLANS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, plans: action.visiblity },
      };
    case "GET_PLANS":
      return {
        ...state,
        plans: action.data,
      };
    case "GET_TEAM_PRESENCE":
      return {
        ...state,
        teamPresence: action.data,
      };
    case "RESET_GET_TEAM_PRESENCE_INFO":
      return {
        ...state,
        teamPresence: [],
      };
    case "TOGGLE_GET_TEAM_PRESENCE_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, teamPresence: action.visiblity },
      };
    case "GET_TEAM_VOIP":
      return {
        ...state,
        teamVoip: action.data,
      };
    case "RESET_GET_TEAM_VOIP_INFO":
      return {
        ...state,
        teamVoip: {},
      };
    case "TOGGLE_GET_TEAM_VOIP_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, teamVoip: action.visiblity },
      };
    case "GET_TEAM_VOICE_MESSAGE":
      return {
        ...state,
        teamVoiceMessage: action.data,
      };
    case "RESET_GET_TEAM_VOICE_MESSAGE_INFO":
      return {
        ...state,
        teamVoiceMessage: {},
      };
    case "TOGGLE_GET_TEAM_VOICE_MESSAGE_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, teamVoiceMessage: action.visiblity },
      };
    case "SEARCH_TEAM_VOIP_HISTORY":
      return {
        ...state,
        teamVoipHistory: {
          data: action.data,
          count: action.total,
          params: action.params,
        },
      };
    case "RESET_TEAM_VOIP_HISTORY":
      return {
        ...state,
        teamVoipHistory: {
          data: [],
          count: 1,
          params: {},
        },
      };
    case "TOGGLE_GET_TEAM_VOIP_HISTORY_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, teamVoipHistory: action.visiblity },
      };
    case "UPDATE_SUB_ID":
      return {
        ...state,
        updateSubscriptionId: action.id,
      };
    case "SEARCH_TEAM_INTERNAL_LEADS":
      return {
        ...state,
        teamInternalLeads: {
          data: action.data,
          count: action.total,
          params: action.params,
        },
      };
    case "RESET_TEAM_INTERNAL_LEADS":
      return {
        ...state,
        teamInternalLeads: {
          data: [],
          count: 1,
          params: {},
        },
      };
    case "TOGGLE_GET_TEAM_INTERNAL_LEADS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, teamInternalLeads: action.visiblity },
      };
    case "GET_TALKTIME":
      return {
        ...state,
        talkTimeLedger: action.talkTimeLedger,
        talkTime: action.talkTime,
      };
    case "GET_TEAM_EVENT_ACTIONS":
      return {
        ...state,
        eventActions: action.data,
      };
    case "RESET_TEAM_EVENT_ACTIONS":
      return {
        ...state,
        eventActions: [],
      };
    case "TOGGLE_GET_TEAM_EVENT_ACTIONS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, eventActions: action.visiblity },
      };
    case "UPDATE_EVENT_ACTION_ID":
      return {
        ...state,
        updateEventActionId: action.id,
      };
    case "VIEW_EVENT_ACTION_ID":
      return {
        ...state,
        viewEventActionId: action.id,
      };

    default:
      return { ...state };
  }
};

export default teams;
