import axios from "axios";

export interface CallRecording {
  recording_url: string;
  recording_status: "OK" | string;
  id: number;
  call_status: "ANSWER" | string;
  call_type: "OUTBOUND" | "INBOUND";
  duration: number;
  phone: string;
  start_time: string;
  end_time: string;
  recording_expire_at: string;
}

export const getCallRecording = async (
  callRecordingId: number
): Promise<CallRecording> => {
  const response: any = await axios.get(
    `/call-history/recording/${callRecordingId}`
  );
  if (response.status === 200) {
    return response.data.data;
  } else {
    throw Error(response.response.data.message);
  }
};
