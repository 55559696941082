import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { store } from "../../../../../redux/storeConfig/store.ts";
import { setExtraDocuments, setPobId } from "../../bv-upload/bv-upload.slice";
import { generateNote } from "../../../../../redux/slices/notes/notes.api";
const MySwal = withReactContent(Swal);

export const toggleBusinessKycBypass = async (
  orgId,
  is_biz_kyc_required,
  comment,
  is_adding_note_optional_config = false
) => {
  try {
    const response = await axios.post(`/org/kyc/${orgId}/bv/toggle`, {
      is_biz_kyc_required,
      comment,
    });

    !is_adding_note_optional_config &&
      (await generateNote({
        id: orgId,
        action_name: `Business KYC Switch`,
        type: "org",
      }));
    store.dispatch(getKyc(orgId));
    return response;
  } catch (e) {
    MySwal.fire({
      title: "Failed",
      text: JSON.stringify(e),
    });
  }
};

export const toggleSecurityAnnouncement = async (
  orgId,
  trial_announcement_toggle
) => {
  try {
    const response = await axios.post(
      `/settings/org/${orgId}/trial-announcement-toggle`,
      {
        trial_announcement_toggle: trial_announcement_toggle ? 1 : 0,
      }
    );

    return response;
  } catch (e) {
    MySwal.fire({
      title: "Failed",
      text: JSON.stringify(e),
    });
  }
};

// ** Get all Data
export const getAllData = () => {
  return async (dispatch) => {
    await axios.get("/org/kyc/search").then((response) => {
      dispatch({
        type: "GET_ALL_KYCS_DATA",
        data: response.data.data.data,
      });
    });
  };
};

// ** Get data on page or row change
export const searchAndFilterKyc = (params) => {
  return async (dispatch) => {
    const {
      page,
      perPage,
      status,
      q,
      trial,
      user_kyc_status,
      business_kyc_status,
    } = params || {};
    await axios
      .get("/org/kyc/search", {
        params: {
          page,
          page_size: perPage,
          status,
          text: q,
          trial,
          user_kyc_status,
          business_kyc_status,
        },
      })
      .then((response) => {
        dispatch({
          type: "SEARCH_KYCS_DATA",
          data: response.data.data.data,
          total: response.data.data.total_items,
          params,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getKyc = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_KYC_LOADER", visiblity: true });
    await axios
      .get(`/org/kyc/${orgId}`)
      .then((response) => {
        dispatch({
          type: "GET_KYC",
          selectedKyc: response.data.data,
        });
        dispatch(getKycDocDisplayUrls(response.data.data));
        dispatch(getBVDocDisplayUrls(response.data.data?.business_kyc || {}));
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_GET_KYC_LOADER", visiblity: false })
      );
  };
};

export const changeBusinessVerificationStatus = async (orgId, status) => {
  try {
    const response = await axios.put(`/admin/org/${orgId}/kyc/bv`, {
      status,
    });
    await generateNote({
      id: orgId,
      action_name: "Change BV Status",
      type: "org",
    });
    store.dispatch(getKyc(orgId));
    return response;
  } catch (e) {
    MySwal.fire({
      title: "Failed",
      text: JSON.stringify(e),
    });
  }
};

// ** Update Kyc data
export const updateKyc = (
  orgId,
  updateData,
  rejectionMessageList = [],
  cb = () => {}
) => {
  return async (dispatch) => {
    await updateKycApi(orgId, updateData)
      .then((response) => {
        if (!response) {
          cb(new Error("Api failed"));
          return;
        }
        dispatch(getKyc(orgId));
        dispatch(searchAndFilterKyc());
        cb();
      })
      .catch((err) => {
        console.log(err);
        cb(new Error("Api failed"));
      });
  };
};

export const updateKycApi = async (orgId, updateData) => {
  const response = await axios.put(`/org/kyc/modify`, {
    ...updateData,
    org_id: orgId,
  });
  await generateNote({ id: orgId, action_name: "KYC modified", type: "org" });
  return response;
};

export const updateBv = async (orgId, updateData) => {
  try {
    const response = await axios.put(`/admin/org/${orgId}/kyc/bv`, {
      ...updateData,
    });
    await generateNote({ id: orgId, action_name: "BV modified", type: "org" });
    store.dispatch(getKyc(orgId));
    return response;
  } catch (e) {
    // MySwal.fire({
    //   title: "Failed",
    //   text: JSON.stringify(e),
    // });
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: `${e.response.data.message}`,
      customClass: { confirmButton: "btn btn-primary" },
      buttonsStyling: false,
    });
  }
};

export const updateKycStatus = (
  orgId,
  status,
  rejectionMessageList = [],
  cb = () => {}
) => {
  let fn = async () => {};
  switch (status) {
    case "APPROVED":
      fn = approveKyc(orgId, cb);
      break;
    case "INCOMPLETE":
      fn = updateKyc(orgId, { status: "INCOMPLETE" }, cb);
      break;
    case "REJECTED":
      fn = rejectKyc(orgId, rejectionMessageList, cb);
      break;
    default:
      break;
  }
  return fn;
};

export const getBVRejectionMessages = async () => {
  const dispatch = store.dispatch;
  try {
    const response = await axios.get(`/org/kyc/bv/rejection-messages`);
    dispatch({
      type: "GET_BV_REJECTION_MESSAGE_LIST",
      data: response.data.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const approveKyc = (orgId, cb = () => {}) => {
  return async (dispatch) => {
    await axios
      .post(`/org/kyc/approve`, { org_id: orgId })
      .then(async (response) => {
        if (!response) {
          cb(new Error("Api failed"));
          return;
        }
        await generateNote({
          id: orgId,
          action_name: "KYC Approved",
          type: "org",
        });
        dispatch(getKyc(orgId));
        dispatch(searchAndFilterKyc());
        cb();
      })
      .catch((err) => {
        console.log(err);
        cb(new Error("Api failed"));
      });
  };
};

export const rejectBV = async (orgId, rejectionMessageList, cb = () => {}) => {
  const dispatch = store.dispatch;
  await axios
    .put(`/admin/org/${orgId}/kyc/bv`, {
      status: "REJECTED",
      rejection_messages: rejectionMessageList,
    })
    .then(async (response) => {
      if (!response) {
        cb(new Error("Api failed"));
        return;
      }
      await generateNote({
        id: orgId,
        action_name: "BV Rejected",
        type: "org",
      });
      dispatch(getKyc(orgId));
      dispatch(searchAndFilterKyc());
      cb();
    })
    .catch((err) => {
      console.log(err);
      cb(new Error("Api failed"));
    });
};

export const rejectKyc = (orgId, rejectionMessageList, cb = () => {}) => {
  return async (dispatch) => {
    await axios
      .post(`/org/kyc/reject`, {
        org_id: orgId,
        rejection_messages: rejectionMessageList,
      })
      .then(async (response) => {
        if (!response) {
          cb(new Error("Api failed"));
          return;
        }
        await generateNote({
          id: orgId,
          action_name: "KYC Rejected",
          type: "org",
        });
        dispatch(getKyc(orgId));
        dispatch(searchAndFilterKyc());
        cb();
      })
      .catch((err) => {
        console.log(err);
        cb(new Error("Api failed"));
      });
  };
};

export const getDocUrl = async (url, cb = () => {}) => {
  try {
    const parsedUrl = new URL(url);
    await axios
      .get(
        `/s3/getObject?no_redirect=true&file_name=${parsedUrl.pathname.replace(
          /^\//,
          ""
        )}`
      )
      .then((response) => {
        if (!response) {
          cb(new Error("Api failed"));
          return;
        }
        cb(null, response.data?.data?.url);
      })
      .catch((err) => {
        console.error(err);
        cb(new Error("Api failed"));
      });
  } catch (error) {
    cb(error);
  }
};

export const getKycRejectionMessageList = () => {
  return async (dispatch) => {
    await axios
      .get(`/org/kyc/rejection-messages`)
      .then((response) => {
        dispatch({
          type: "GET_KYC_REJECTION_MESSAGE_LIST",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getKycDocDisplayUrls = (kyc) => {
  return async (dispatch) => {
    if (kyc.poa_front) {
      dispatch({
        type: "TOGGLE_GET_POA_FRONT_DISPLAY_URL_LOADER",
        visiblity: true,
      });
      getDocUrl(kyc.poa_front, (err, url) => {
        dispatch({
          type: "TOGGLE_GET_POA_FRONT_DISPLAY_URL_LOADER",
          visiblity: false,
        });
        if (!err && url) {
          dispatch({
            type: "KYC_POA_FRONT_DISPLAY_URL_RECEIVED",
            url: url,
          });
        }
      });
    }

    if (kyc.poa_back) {
      dispatch({
        type: "TOGGLE_GET_POA_BACK_DISPLAY_URL_LOADER",
        visiblity: true,
      });
      getDocUrl(kyc.poa_back, (err, url) => {
        dispatch({
          type: "TOGGLE_GET_POA_BACK_DISPLAY_URL_LOADER",
          visiblity: false,
        });
        if (!err && url) {
          dispatch({
            type: "KYC_POA_BACK_DISPLAY_URL_RECEIVED",
            url: url,
          });
        }
      });
    }

    if (kyc.photo) {
      dispatch({
        type: "TOGGLE_GET_SELF_PHOTO_DISPLAY_URL_LOADER",
        visiblity: true,
      });
      getDocUrl(kyc.photo, (err, url) => {
        dispatch({
          type: "TOGGLE_GET_SELF_PHOTO_DISPLAY_URL_LOADER",
          visiblity: false,
        });
        if (!err && url) {
          dispatch({
            type: "KYC_SELF_PHOTO_DISPLAY_URL_RECEIVED",
            url: url,
          });
        }
      });
    }

    if (kyc.poi_front) {
      dispatch({
        type: "TOGGLE_GET_POI_FRONT_DISPLAY_URL_LOADER",
        visiblity: true,
      });
      getDocUrl(kyc.poi_front, (err, url) => {
        dispatch({
          type: "TOGGLE_GET_POI_FRONT_DISPLAY_URL_LOADER",
          visiblity: false,
        });
        if (!err && url) {
          dispatch({
            type: "KYC_POI_FRONT_DISPLAY_URL_RECEIVED",
            url: url,
          });
        }
      });
    }

    if (kyc.poi_back) {
      dispatch({
        type: "TOGGLE_GET_POI_BACK_DISPLAY_URL_LOADER",
        visiblity: true,
      });
      getDocUrl(kyc.poi_back, (err, url) => {
        dispatch({
          type: "TOGGLE_GET_POI_BACK_DISPLAY_URL_LOADER",
          visiblity: false,
        });
        if (!err && url) {
          dispatch({
            type: "KYC_POI_BACK_DISPLAY_URL_RECEIVED",
            url: url,
          });
        }
      });
    }
  };
};

export const getBVDocDisplayUrls = (kyc) => {
  return async (dispatch) => {
    if (kyc?.pob_id) {
      dispatch(setPobId(kyc.pob_id));
    }
    if (kyc.pob_front) {
      dispatch({
        type: "TOGGLE_GET_POB_FRONT_DISPLAY_URL_LOADER",
        visiblity: true,
      });
      getDocUrl(kyc.pob_front, (err, url) => {
        dispatch({
          type: "TOGGLE_GET_POB_FRONT_DISPLAY_URL_LOADER",
          visiblity: false,
        });
        if (!err && url) {
          dispatch({
            type: "BV_POB_FRONT_DISPLAY_URL_RECEIVED",
            url: url,
          });
        }
      });
    }

    if (kyc.pob_back) {
      dispatch({
        type: "TOGGLE_GET_POB_BACK_DISPLAY_URL_LOADER",
        visiblity: true,
      });
      getDocUrl(kyc.pob_back, (err, url) => {
        dispatch({
          type: "TOGGLE_GET_POB_BACK_DISPLAY_URL_LOADER",
          visiblity: false,
        });
        if (!err && url) {
          dispatch({
            type: "BV_POB_BACK_DISPLAY_URL_RECEIVED",
            url: url,
          });
        }
      });
    }
    if (Array.isArray(kyc?.extra_documents)) {
      const extraDocuments = await Promise.all(
        kyc.extra_documents.map(async (extraDoc, index) => {
          const { proof_back, proof_front, proof_id, proof_name } = extraDoc;
          let frontUrl = "";
          let backUrl = "";
          if (proof_front) {
            await getDocUrl(proof_front, (err, url) => {
              if (!err && url) {
                frontUrl = url;
              }
            });
          }
          if (proof_back) {
            await getDocUrl(proof_back, (err, url) => {
              if (!err && url) {
                backUrl = url;
              }
            });
          }
          return {
            docType: proof_name,
            frontFace: null,
            backFace: null,
            id: index + 1,
            proof_id,
            frontUrl,
            backUrl,
          };
        })
      );
      dispatch(setExtraDocuments(extraDocuments));
    }
  };
};

export const clearSelectedKycData = () => {
  return {
    type: "CLEAR_KYC_DATA",
  };
};

export const getDigioKycList = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_DIGIO_KYCS_LOADER", visiblity: true });
    await axios
      .get(`/digio/org/${orgId}`)
      .then((response) => {
        dispatch({
          type: "GET_DIGIO_KYC_LIST",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_GET_DIGIO_KYCS_LOADER", visiblity: false })
      );
  };
};

export const getKycDocTypesList = () => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_KYC_UPLOAD_LOADER", visiblity: true });
    await axios
      .get(`/org/kyc/doc-list`)
      .then((response) => {
        dispatch({
          type: "GET_DOC_TYPES_LIST",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_KYC_UPLOAD_LOADER", visiblity: false })
      );
  };
};

export const getKycBvDocTypesList = async () => {
  const dispatch = store.dispatch;
  dispatch({ type: "TOGGLE_KYC_BV_UPLOAD_LOADER", visiblity: true });
  return await axios
    .get(`/org/kyc/bv/doc-list`)
    .then((response) => {
      dispatch({
        type: "GET_BV_DOC_TYPES_LIST",
        data: response.data.data,
      });
      return response;
    })
    .catch((err) => console.log(err))
    .then(() =>
      dispatch({ type: "TOGGLE_KYC_BV_UPLOAD_LOADER", visiblity: false })
    );
};

export const getPreSignDocUploadUrlApi = (fileName, fileType) => {
  return axios
    .get(`/s3/presignURL`, {
      params: {
        file_name: fileName,
        file_type: fileType,
      },
    })
    .then((response) => response.data.data)
    .catch((err) => console.log(err));
};

export const uploadFileToPreSignUrlApi = (file, url, fileType) => {
  return axios({
    method: "put",
    url: url,
    headers: {
      "Content-Type": fileType,
    },
    data: file,
  }).then((response) => response.data);
};

export const copyKyc = (destinationOrg, sourceOrg) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_KYC_LOADER", visiblity: true });
    await axios
      .post("/org/kyc/copy", {
        source_org_id: sourceOrg.id,
        destination_org_id: destinationOrg.id,
      })
      .then(async (response) => {
        await generateNote({
          id: destinationOrg.id,
          action_name: "KYC Copied",
          type: "org",
        });
        dispatch(getKyc(destinationOrg.id));
        MySwal.fire({
          icon: "success",
          title: "Kyc copied successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_KYC_LOADER", visiblity: false })
      );
  };
};

export const copyBusinessKyc = (destinationOrg, sourceOrg) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_KYC_LOADER", visiblity: true });
    await axios
      .post("/admin/org/kyc/copy/bv", {
        source_org_id: sourceOrg.id,
        destination_org_id: destinationOrg.id,
      })
      .then(async (response) => {
        await generateNote({
          id: destinationOrg.id,
          type: "org",
          action_name: "BV Copied",
        });
        dispatch(getKyc(destinationOrg.id));
        MySwal.fire({
          icon: "success",
          title: "Kyc copied successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_KYC_LOADER", visiblity: false })
      );
  };
};

export const sendUserKycLinkViaSMS = (org) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_SEND_USER_KYC_LINK_LOADER", visiblity: true });
    await axios
      .post("/admin/org/kyc/digio", {
        org_id: org.id,
      })
      .then(async (response) => {
        await generateNote({
          id: org.id,
          action_name: "Send User KYC Link via SMS",
          type: "org",
        });
        dispatch(getKyc(org.id));
        dispatch(getDigioKycList(org.id));
        MySwal.fire({
          icon: "success",
          title: `SMS sent successfully to mobile ${response.data.data.user_phone}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({ type: "TOGGLE_SEND_USER_KYC_LINK_LOADER", visiblity: false })
      );
  };
};

// // ** Add new user
// export const addUser = (user) => {
//   return (dispatch, getState) => {
//     axios
//       .post("/apps/users/add-user", user)
//       .then((response) => {
//         dispatch({
//           type: "ADD_USER",
//           user,
//         });
//       })
//       .then(() => {
//         dispatch(getData(getState().users.params));
//         dispatch(searchAndFilterKyc());
//       })
//       .catch((err) => console.log(err));
//   };
// };

// // ** Delete user
// export const deleteUser = (id) => {
//   return (dispatch, getState) => {
//     axios
//       .delete("/apps/users/delete", { id })
//       .then((response) => {
//         dispatch({
//           type: "DELETE_USER",
//         });
//       })
//       .then(() => {
//         dispatch(getData(getState().users.params));
//         dispatch(searchAndFilterKyc());
//       });
//   };
// };
