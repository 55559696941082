import { Fragment } from "react";
import { Spinner } from "reactstrap";

const SpinnerFlex = ({ size = "", color = "primary" }) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-center">
        <Spinner color={color} size={size} />
      </div>

      {/* <div className='d-flex justify-content-between align-items-center'>
        <strong>Loading...</strong>
        <Spinner />
      </div> */}
    </Fragment>
  );
};
export default SpinnerFlex;
