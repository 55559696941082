// ** Initial State
const initialState = {
  toggleBusinessKycBypassStatus: "idle",
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedKyc: null,
  rejectionMessageList: [],
  bvRejectionMessageList: [],
  digioKycList: [],
  loaders: {
    kyc: false,
    digioKycList: false,
    poaFront: false,
    poaBack: false,
    poiFront: false,
    poiBack: false,
    pobFront: false,
    pobBack: false,
    photo: false,
    kycUpload: false,
    kycBvUpload: false,
    sendUserKycLink: false,
  },
  allAllowedDocTypes: [],
  bVAllowedDocTypes: [],
  kycUpload: null,
  kycBvUpload: null,
};

const kycs = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_KYCS_DATA":
      return { ...state, allData: action.data };
    case "SEARCH_KYCS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "GET_KYC":
      return { ...state, selectedKyc: action.selectedKyc };
    case "KYC_POA_FRONT_DISPLAY_URL_RECEIVED":
      return {
        ...state,
        selectedKyc: {
          ...state.selectedKyc,
          poa_front_display_url: action.url,
        },
      };
    case "KYC_POA_BACK_DISPLAY_URL_RECEIVED":
      return {
        ...state,
        selectedKyc: {
          ...state.selectedKyc,
          poa_back_display_url: action.url,
        },
      };
    case "KYC_POI_FRONT_DISPLAY_URL_RECEIVED":
      return {
        ...state,
        selectedKyc: {
          ...state.selectedKyc,
          poi_front_display_url: action.url,
        },
      };
    case "KYC_POI_BACK_DISPLAY_URL_RECEIVED":
      return {
        ...state,
        selectedKyc: {
          ...state.selectedKyc,
          poi_back_display_url: action.url,
        },
      };
    case "KYC_SELF_PHOTO_DISPLAY_URL_RECEIVED":
      return {
        ...state,
        selectedKyc: {
          ...state.selectedKyc,
          self_photo_display_url: action.url,
        },
      };
    case "BV_POB_FRONT_DISPLAY_URL_RECEIVED":
      return {
        ...state,
        selectedKyc: {
          ...state.selectedKyc,
          pob_front_display_url: action.url,
        },
      };
    case "BV_POB_BACK_DISPLAY_URL_RECEIVED":
      return {
        ...state,
        selectedKyc: {
          ...state.selectedKyc,
          pob_back_display_url: action.url,
        },
      };
    case "CLEAR_KYC_DATA":
      return {
        ...state,
        selectedKyc: null,
      };
    case "GET_KYC_REJECTION_MESSAGE_LIST":
      return { ...state, rejectionMessageList: action.data };
    case "GET_BV_REJECTION_MESSAGE_LIST":
      return { ...state, bvRejectionMessageList: action.data };
    case "GET_DIGIO_KYC_LIST":
      return { ...state, digioKycList: action.data };
    case "CLEAR_DIGIO_KYC_LIST":
      return { ...state, digioKycList: [] };
    case "TOGGLE_GET_KYC_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, kyc: action.visiblity },
      };
    case "TOGGLE_GET_DIGIO_KYCS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, digioKycList: action.visiblity },
      };
    case "TOGGLE_GET_POA_FRONT_DISPLAY_URL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, poaFront: action.visiblity },
      };
    case "TOGGLE_GET_POA_BACK_DISPLAY_URL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, poaBack: action.visiblity },
      };
    case "TOGGLE_GET_POI_FRONT_DISPLAY_URL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, poiFront: action.visiblity },
      };
    case "TOGGLE_GET_POI_BACK_DISPLAY_URL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, poiBack: action.visiblity },
      };
    case "TOGGLE_GET_SELF_PHOTO_DISPLAY_URL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, photo: action.visiblity },
      };
    case "TOGGLE_KYC_UPLOAD_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, kycUpload: action.visiblity },
      };
    case "TOGGLE_KYC_BV_UPLOAD_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, kycBvUpload: action.visiblity },
      };
    case "GET_DOC_TYPES_LIST":
      return {
        ...state,
        allAllowedDocTypes: action.data,
      };
    case "GET_BV_DOC_TYPES_LIST":
      return {
        ...state,
        bVAllowedDocTypes: action.data,
      };
    case "CLEAR_KYC_UPLOAD_DATA":
      return {
        ...state,
        kycUpload: null,
        kycBvUpload: null,
      };
    case "SET_POA_DOC_TYPE":
      return {
        ...state,
        kycUpload: { ...state.kycUpload, poa_name: action.data },
      };
    case "SET_POI_DOC_TYPE":
      return {
        ...state,
        kycUpload: { ...state.kycUpload, poi_name: action.data },
      };
    case "SET_POB_DOC_TYPE":
      return {
        ...state,
        kycBvUpload: { ...state.kycBvUpload, pob_name: action.data },
      };
    case "TOGGLE_SEND_USER_KYC_LINK_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, sendUserKycLink: action.visiblity },
      };
    default:
      return { ...state };
  }
};
export default kycs;
