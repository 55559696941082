// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  loaders: {
    list: false,
    internalLead: false,
  },
  internalLead: {},
};

const internalLeads = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_INTERNAL_LEAD_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "TOGGLE_SEARCH_INTERNAL_LEAD_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, list: action.visiblity },
      };
    case "GET_INTERNAL_LEAD":
      return { ...state, internalLead: action.data };
    case "TOGGLE_GET_INTERNAL_LEAD_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, internalLead: action.visiblity },
      };
    case "RESET_INTERNAL_LEAD":
      return { ...state, internalLead: {} };
    default:
      return { ...state };
  }
};
export default internalLeads;
