// ** Initial State
const initialState = {
  paymentLinks: [],
  paymentLinks_total: 0,
  payments: [],
  payments_total: 0,
  subscriptions: [],
  subscriptions_total: 0,
  selectedTeam: null,
  gstData: {},
};

const Subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PAYMENT_LINKS":
      return {
        ...state,
        paymentLinks: action.data,
        paymentLinks_total: action.total,
      };
    case "GET_ALL_PAYMENTS":
      return {
        ...state,
        payments: action.data,
        payments_total: action.total,
      };

    case "SET_GSTIN_DATA":
      return {
        ...state,
        gstData: { ...state.gstData, ...action.data },
      };
    case "RESET_GSTIN_DATA":
      return {
        ...state,
        gstData: {},
      };
    default:
      return { ...state };
  }
};
export default Subscriptions;
