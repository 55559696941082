import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../../redux/storeConfig/store";
import {
  APIResponse,
  GenericAPIResponse,
  getDataOrError,
  initialApiResponse,
} from "../../../services/utils";
import { MySwal } from "../../../services/utils";

type ODState = Readonly<{
  configureODAccount: APIResponse<ConfigureODAccountResponse>;
}>;

const initialState: ODState = {
  configureODAccount: initialApiResponse,
};

export const configureODAccountAsync = createAsyncThunk(
  "od/configureODAccount",
  async (json: string) => {
    const response = await configureODAccount(json);
    return response;
  }
);

export const odSlice = createSlice({
  name: "od",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(configureODAccountAsync.pending, (state) => {
        state.configureODAccount = {
          response: null,
          status: "loading",
          error: null,
        };
      })
      .addCase(configureODAccountAsync.fulfilled, (state, action) => {
        state.configureODAccount = {
          response: action.payload,
          status: "success",
          error: null,
        };
      })
      .addCase(configureODAccountAsync.rejected, (state, action) => {
        state.configureODAccount = {
          response: null,
          status: "failed",
          error: action.error,
        };
      });
  },
});

export interface ConfigureODAccountResponse {
  errors?: JSONErrors[];
}
export interface JSONErrors {
  staff_first_name: string;
  staff_last_name?: string | null;
  staff_phone_number: string;
  paid_superfone_number: string;
  message: string;
}

export const configureODAccount = async (json: string) => {
  const payload = JSON.parse(json);
  try {
    const response = await axios.post<
      GenericAPIResponse<ConfigureODAccountResponse>
    >(`/admin/org/configure-od-acccount`, { ...payload });
    const isSuccess = response.status === 200;
    MySwal.fire({
      icon: isSuccess ? "success" : "error",
      title: isSuccess ? "Success" : "Failed",
    });
    return getDataOrError(response) as ConfigureODAccountResponse;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new Error(e?.response?.data?.message || "");
    }
    throw e;
  }
};

export const selectODReducer = (state: RootState) => state.od;

export const selectConfigureODAccount = createSelector(
  [selectODReducer],
  (reducer) => reducer.configureODAccount
);

export default odSlice.reducer;
