// ** Initial State
const initialState = {
  feedback: {},
  info: {},
  summary: {},
  loaders: {
    feedback: false,
    info: false,
  },
  search_data: []
};

const call = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CALL_FEEDBACK":
      return { ...state, feedback: action.data };
    case "TOGGLE_GET_CALL_FEEDBACK_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, feedback: action.visiblity },
      };
    case "RESET_CALL_FEEDBACK":
      return { ...state, feedback: {} };
    case "GET_CALL_INFO":
      return { ...state, info: action.data };
    case "TOGGLE_GET_CALL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, info: action.visiblity },
      };
    case "RESET_CALL":
      return { ...state, info: {}, transcript: {}, summary: {} };
    case 'GET_CALL_TRANSCRIPT':
        return { ...state, transcript: action.data };
    case 'GET_CALL_SUMMARY':
        return { ...state, summary: action.data };
    case 'GET_SEARCH_RESULTS':
        return { ...state, search_data: action.data };
    default:
      return { ...state };
  }
};
export default call;
