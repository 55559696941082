// ** Redux Imports
import { combineReducers } from "@reduxjs/toolkit";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import chat from "../../views/apps/chat/store/reducer";
import todo from "../../views/apps/todo/store/reducer";
import email from "../../views/apps/email/store/reducer";
import invoice from "../../views/apps/invoice/store/reducer";
import calendar from "../../views/apps/calendar/store/reducer";
import ecommerce from "../../views/apps/ecommerce/store/reducer";
import dataTables from "../../views/tables/data-tables/store/reducer";
import users from "../../views/superfone/user/store/reducer";
import kycs from "../../views/superfone/kyc/store/reducer";
import customers from "../../views/superfone/customer/store/reducer";
import teams from "../../views/superfone/team/store/reducer";
import team from "../../views/superfone/team/store/team.slice";
import onboard from "../../views/superfone/onboard-user/store/onboard.slice";
import subscription from "../../views/superfone/subscriptions/store/reducer";
import invite_code from "../../views/superfone/invite-codes/store/reducer";
import short_url from "../../views/superfone/short-url/store/reducer";
import voips from "../../views/superfone/voip-numbers/store/reducer";
import callHistory from "../../views/superfone/call-history/store/reducer/index";
import call from "../../views/superfone/call/store/reducer/index";
import bvUpload from "../../views/superfone/kyc/bv-upload/bv-upload.slice";
import kycTable from "../../views/superfone/kyc/list/kyc-table.slice";
import history from "../../views/superfone/team/history/history.slice";
import internalLeads from "../../views/superfone/internal-leads/store/reducer";

import campaigns from "../../views/superfone/campaigns/store/reducer";
import referrals from "../../views/superfone/referrals/store/reducer";
import rewards from "../../views/superfone/rewards/store/reducer";
import userCampaigns from "../../views/superfone/user-campaigns/store/reducer";
import od from "../../views/superfone/od/od.slice";
import notes from "../../redux/slices/notes/notes.slice";
import WhatsappAccountsReducer from "../../views/superfone/whatsapp/store/reducer";
import integrations from "../../views/superfone/integrations/integrations.slice";
import { superfoneApi } from "../api/superfone-api";
const rootReducer = combineReducers({
  od,
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  history,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  kycs,
  customers,
  team,
  teams,
  onboard,
  subscription,
  invite_code,
  short_url,
  voips,
  callHistory,
  call,
  bvUpload,
  kycTable,
  internalLeads,
  campaigns,
  referrals,
  rewards,
  userCampaigns,
  notes,
  whatsappAccounts: WhatsappAccountsReducer,
  integrations,
  [superfoneApi.reducerPath]: superfoneApi.reducer,
});

export default rootReducer;
