// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  loaders: {
    list: false,
    campaign: false,
  },
  campaign: {},
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_CAMPAIGN_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "TOGGLE_SEARCH_CAMPAIGN_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, list: action.visiblity },
      };
    case "GET_CAMPAIGN":
      return { ...state, campaign: action.data };
    case "TOGGLE_GET_CAMPAIGN_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, campaign: action.visiblity },
      };
    case "RESET_CAMPAIGN":
      return { ...state, campaign: {} };
    default:
      return { ...state };
  }
};
export default campaigns;
