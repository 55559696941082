import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import { LoaderState, RootState } from "../../../../redux/storeConfig/store";
import { History } from "./history.types";

type KYCTableState = Readonly<{
  history: History | null;
  historyLoader: LoaderState;
}>;

const initialState: KYCTableState = {
  history: null,
  historyLoader: "idle",
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryAsync.pending, (state) => {
        state.historyLoader = "loading";
      })
      .addCase(getHistoryAsync.fulfilled, (state, action) => {
        state.history = action.payload;
        state.historyLoader = "success";
      })
      .addCase(getHistoryAsync.rejected, (state) => {
        state.historyLoader = "failed";
      });
  },
});

export const selectHistoryReducer = (state: RootState) => state.history;

export const selectHistory = createSelector(
  [selectHistoryReducer],
  (reducer) => reducer.history
);

export const selectHistoryLoader = createSelector(
  [selectHistoryReducer],
  (reducer) => reducer.historyLoader
);

export default historySlice.reducer;

export const getHistoryAsync = createAsyncThunk(
  "history/get-history",
  async (orgId: number): Promise<History> => {
    const response = await axios.get(`/admin/org/${orgId}/kyc/action-history`);
    return response.data.data;
  }
);
