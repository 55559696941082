// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  loaders: {
    list: false,
    referral: false,
    rewards: false,
  },
  referral: {},
  rewards: {
    data: [],
    total: 1,
    params: {},
  },
};

const referrals = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_REFERRAL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "TOGGLE_SEARCH_REFERRAL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, list: action.visiblity },
      };
    case "GET_REFERRAL":
      return { ...state, referral: action.data };
    case "TOGGLE_GET_REFERRAL_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, referral: action.visiblity },
      };
    case "RESET_REFERRAL":
      return { ...state, referral: {} };
    case "GET_REFERRAL_REWARDS":
      return {
        ...state,
        rewards: {
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    case "TOGGLE_GET_REFERRAL_REWARDS_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, rewards: action.visiblity },
      };
    case "RESET_REFERRAL_REWARDS":
      return {
        ...state,
        rewards: {
          data: [],
          total: 1,
          params: {},
        },
      };
    default:
      return { ...state };
  }
};
export default referrals;
