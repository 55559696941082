import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { APIResponse, initialApiResponse } from "../../../../services/utils";
import { FetchUserPayload, FetchUserResponse } from "./types/fetch-user.types";
import {
  addUser,
  getUserFormData,
  onSearchUser,
  postUserFormData,
} from "./api";
import { RootState, store } from "../../../../redux/storeConfig/store";
import debounce from "lodash/debounce";
import {
  GetUserFormDataPayload,
  GetUserFormResponse,
} from "./types/get-user-form-data.types";
import {
  PostUserFormDataPayload,
  PostUserFormDataResponse,
} from "./types/post-user-form-data.types";
import { AddUserPayload, AddUserResponse } from "./types/add-user.types";

type OnboardState = Readonly<{
  fetchUsers: APIResponse<FetchUserResponse>;
  getUserFormData: APIResponse<GetUserFormResponse>;
  postUserFormData: APIResponse<PostUserFormDataResponse>;
  addUser: APIResponse<AddUserResponse>;
}>;

const initialState: OnboardState = {
  fetchUsers: initialApiResponse,
  getUserFormData: initialApiResponse,
  postUserFormData: initialApiResponse,
  addUser: initialApiResponse,
};

export const searchUserAsync = createAsyncThunk(
  "onboard/search-user",
  async (payload: FetchUserPayload) => {
    const response = onSearchUser(payload);
    return response;
  }
);

export const getUserFormDataAsync = createAsyncThunk(
  "onboard/get-user-form-data",
  async (payload: GetUserFormDataPayload) => {
    const response = await getUserFormData(payload);
    return response;
  }
);

export const postUserFormDataAsync = createAsyncThunk(
  "onboard/post-user-form-data",
  async (payload: PostUserFormDataPayload, thunkAPI) => {
    const response = await postUserFormData(payload);
    const { dispatch } = thunkAPI;
    dispatch(getUserFormDataAsync({ user_id: payload.user_id }));
    return response;
  }
);

export const addUserAsync = createAsyncThunk(
  "onboard/add-user",
  async (payload: AddUserPayload) => {
    const response = await addUser(payload);
    return response;
  }
);

export const debouncedSearch = debounce((payload: FetchUserPayload) => {
  const dispatch = store.dispatch;
  dispatch(searchUserAsync(payload));
}, 300);

export const onboardSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(searchUserAsync.rejected, (state, action) => {
        state.fetchUsers = {
          response: null,
          error: action.error,
          status: "failed",
        };
      })
      .addCase(searchUserAsync.fulfilled, (state, action) => {
        state.fetchUsers = {
          response: action.payload,
          error: null,
          status: "success",
        };
      })
      .addCase(searchUserAsync.pending, (state) => {
        state.fetchUsers = {
          response: state.fetchUsers.response,
          error: null,
          status: "loading",
        };
      })
      .addCase(getUserFormDataAsync.rejected, (state, action) => {
        state.getUserFormData = {
          response: null,
          error: action.error,
          status: "failed",
        };
      })
      .addCase(getUserFormDataAsync.fulfilled, (state, action) => {
        state.getUserFormData = {
          response: action.payload,
          error: null,
          status: "success",
        };
      })
      .addCase(getUserFormDataAsync.pending, (state) => {
        state.getUserFormData = {
          response: state.getUserFormData.response,
          error: null,
          status: "loading",
        };
      })
      .addCase(postUserFormDataAsync.rejected, (state, action) => {
        state.postUserFormData = {
          response: null,
          error: action.error,
          status: "failed",
        };
      })
      .addCase(postUserFormDataAsync.fulfilled, (state, action) => {
        state.postUserFormData = {
          response: action.payload,
          error: null,
          status: "success",
        };
      })
      .addCase(postUserFormDataAsync.pending, (state) => {
        state.postUserFormData = {
          response: state.postUserFormData.response,
          error: null,
          status: "loading",
        };
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.addUser = {
          response: null,
          error: action.error,
          status: "failed",
        };
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.addUser = {
          response: action.payload,
          error: null,
          status: "success",
        };
      })
      .addCase(addUserAsync.pending, (state) => {
        state.addUser = {
          response: state.addUser.response,
          error: null,
          status: "loading",
        };
      });
  },
});

export const selectOnboardState = (state: RootState) => state.onboard;

export const selectFetchUsers = createSelector(
  [selectOnboardState],
  (onboard) => onboard.fetchUsers
);

export const selectGetUserFormData = createSelector(
  [selectOnboardState],
  (onboard) => onboard.getUserFormData
);

export const selectPostUserFormData = createSelector(
  [selectOnboardState],
  (onboard) => onboard.postUserFormData
);

export const selectFetchUsersResponse = createSelector(
  [selectFetchUsers],
  (fetchUsers) => fetchUsers.response
);

export const selectAddUser = createSelector(
  [selectOnboardState],
  (reducer) => reducer.addUser
);

export const selectUsers = createSelector(
  [selectFetchUsersResponse],
  (response) => response?.data || []
);

export default onboardSlice.reducer;
