import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  extraDocuments: [],
  pobId: "",
};

export const bvUploadSlice = createSlice({
  name: "bv-upload",
  initialState,
  reducers: {
    setExtraDocuments: (state, action) => {
      state.extraDocuments = action.payload;
    },
    setPobId: (state, action) => {
      state.pobId = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setExtraDocuments, setPobId } = bvUploadSlice.actions;

export const selectBvUploadReducer = (state) => state.bvUpload;

export const selectExtraDocuments = createSelector(
  [selectBvUploadReducer],
  (bvUpload) => bvUpload.extraDocuments
);

export const selectPobId = createSelector(
  [selectBvUploadReducer],
  (bvUpload) => bvUpload.pobId
);

export default bvUploadSlice.reducer;
