// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  loaders: {
    action: false,
  },
};

const voips = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_VOIP_NUMBERS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    case "TOGGLE_VOIP_ACTION_LOADER":
      return {
        ...state,
        loaders: { ...state.loaders, action: action.visiblity },
      };
    default:
      return { ...state };
  }
};
export default voips;
